<template>
  <component
    :is="to || prismicTo || href || prismicHref ? (isInternal ? 'nuxt-link' : 'a') : 'button'"
    :to="to ? to : prismicTo"
    :target="!isInternal ? '_blank' : null"
    :type="isFakeButton ? 'button' : type"
    class="button"
    :class="[size ? 'button--' + size : '', isFullWidth ? 'button--full' : '', isHollow ? 'button--hollow' : '', isAltBG ? 'button--alt' : '', isChoice ? 'button--choice' : '', price ? 'button--price' : '']"
    @click="$emit('click')"
  >
    <template v-if="price">
      <Price :price="price" :salePrice="salePrice" />
      <span>{{ text }}</span>
    </template>
    <template v-else>
      <SvgIcon v-if="isInstagram" name="instagram" title="Instagram" />
      <SvgIcon v-if="hasCheck" name="checkmark-full" />
      <SvgIcon v-if="hasPlus" name="plus-full" />
      <span v-html="text"></span>
      <SvgIcon v-if="hasClose" name="close" />
      <SvgIcon v-else-if="!hideArrow" name="chevron-right" />
    </template>
  </component>
</template>


<script>
  import prismicLinkMixin from '@/mixins/prismicLinkMixin';

  export default {
    name: 'Button',

    mixins: [ prismicLinkMixin ],

    components: {
      SvgIcon: () => import('@/components/global/SvgIcon'),
      Price: () => import('@/components/shop/Price')
    },

    props: {
      type: {
        type: String,
        default: null
      },
      size: {
        type: String,
        default: null
      },
      isFullWidth: {
        type: Boolean,
        default: false
      },
      hideArrow: {
        type: Boolean,
        default: false
      },
      isInstagram: {
        type: Boolean,
        default: false
      },
      isFakeButton: {
        type: Boolean,
        default: false
      },
      isHollow: {
        type: Boolean,
        default: false
      },
      isAltBG: {
        type: Boolean,
        default: false
      },
      isChoice: {
        type: Boolean,
        default: false
      },
      hasCheck: {
        type: Boolean,
        default: false
      },
      hasPlus: {
        type: Boolean,
        default: false
      },
      hasClose: {
        type: Boolean,
        default: false
      },
      price: {
        type: [String, Number],
        default: null
      },
      salePrice: {
        type: [String, Number],
        default: null
      }
    },

    mounted() {
      if(!this.isInternal && (this.href || this.prismicHref)) {
        this.$el.setAttribute('href', this.href ? this.href : this.prismicHref);
      }
    }
  }
</script>
